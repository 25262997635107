import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ProjectCard from "../Projects/ProjectCards";
import cmc from "../../Assets/Projects/cmc.png";
import bsc from "../../Assets/Projects/bsc.png";
import battle from "../../Assets/Projects/battle.png";
import academy from "../../Assets/Projects/academy.png";
import bot from "../../Assets/Projects/bot.png";
import a2024 from "../../Assets/Projects/2024.png";
import a2025 from "../../Assets/Projects/2025.png";
import finance from "../../Assets/Projects/finance.png";
import artcart from "../../Assets/Projects/artcart.png";
import v1 from "../../Assets/Projects/v1.png";
import v2 from "../../Assets/Projects/v2.png";
import cin from "../../Assets/Projects/cin.png";
import plan from "../../Assets/../Assets/Soumyajit_Behera-BIT_MESRA.pdf";

import {
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";

import { 
  FaFacebook,
  FaReddit,
  FaMedium,
  FaLinkedin
 } from "react-icons/fa";

function Home2() {
  return (<Container fluid className="project-section">
    <Particle />
    <Container>
      <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <ProjectCard
            imgPath={a2024}
            isBlog={false}
            title="2024"
            description=""
            ghLink="https://prezi.com/p/0bavzkvjrb5a/?present=1"
            demoLink=""
          />
          <ProjectCard
            imgPath={a2025}
            isBlog={false}
            title="2025 Roadmap"
            description=""
            ghLink="https://prezi.com/p/b9qlvw4js3sv/?present=1"
            demoLink=""
          />
          <ProjectCard
            imgPath={cmc}
            isBlog={false}
            title="Coin Market Cap"
            description="The current price of Vista on CoinMarketCap is $24 USD. This value reflects the growing demand and market interest in Vista, a promising asset in the cryptocurrency space, offering investors significant potential for growth and profitability."
            ghLink="https://coinmarketcap.com/currencies/koloop-basic/#Markets"
            demoLink=""
          />
          <ProjectCard
            imgPath={bsc}
            isBlog={false}
            title="BSCScan"
            description="Vista has achieved millions of transactions on BSCScan, showcasing its widespread adoption and trust within the blockchain community. This high transaction volume highlights Vista's growing influence, reliability, and active engagement on the Binance Smart Chain, solidifying its position in the market."
            ghLink="https://bscscan.com/token/0x493361d6164093936c86dcb35ad03b4c0d032076"
            demoLink=""
          />
          <ProjectCard
            imgPath={battle}
            isBlog={false}
            title="Vista Battle"
            description="Vista Battle is a skill-based gaming platform where players compete in exciting challenges to showcase their talents. Offering a variety of games, it combines entertainment with competition, allowing users to earn rewards and engage in thrilling, skill-driven battles."
            ghLink="http://vistabattle.com/"
            demoLink=""
          />
          <ProjectCard
            imgPath={academy}
            isBlog={false}
            title="BlockVista Trading Academy"
            description="Block Vista Trading Academy offers comprehensive courses on blockchain, cryptocurrency, and trading strategies. Users can gain valuable knowledge, enhance their skills, and confidently navigate the digital finance landscape, empowering them to succeed in the rapidly evolving world of DeFi and crypto markets."
            ghLink="https://blockvista.academy/"
            demoLink=""
          />
          <ProjectCard
            imgPath={bot}
            isBlog={false}
            title="Vista Bot Father"
            description="Vista Bot Father is an advanced auto trading bot designed to optimize cryptocurrency trading strategies. By utilizing cutting-edge algorithms, it automatically executes trades based on market analysis, helping users maximize profits while minimizing risk, offering a seamless, efficient trading experience."
            ghLink="https://vistabotfather.com"
            demoLink=""
          />
          <ProjectCard
            imgPath={finance}
            isBlog={false}
            title="Vista Finance"
            description="Vista Finance is the parent company behind innovative products like ArtCart, Block Vista Trading Academy, and more. By leveraging blockchain technology, Vista Finance empowers users with secure, decentralized solutions for finance, art, education, and trading, shaping the future of digital industries."
            ghLink="https://vistafinance.io"
            demoLink=""
          />
          <ProjectCard
            imgPath={artcart}
            isBlog={false}
            title="Vista Art Cart"
            description="Vista Art Cart is a cutting-edge NFT marketplace that connects artists and collectors in the digital art world. With secure blockchain technology, it offers a seamless platform for buying, selling, and showcasing unique, verified NFTs, revolutionizing the art industry."
            ghLink="https://nfts.vistafinance.io"
            demoLink=""
          />
          <ProjectCard
            imgPath={cin}
            isBlog={false}
            title="Vista Company Registration"
            description="VISTA FINANCE DAPPS SOLUTION LIMITED, registered in the UK with company number 15768716, specializes in decentralized finance (DeFi) solutions. The company develops innovative products and services, leveraging blockchain technology to enhance financial freedom, security, and user experience globally."
            ghLink="https://find-and-update.company-information.service.gov.uk/company/15768716"
            demoLink=""
          />
          <ProjectCard
            imgPath={v1}
            isBlog={true}
            title="Vista Finance Decentralized Plan 2024"
            description="Vista Finance is introducing a groundbreaking decentralized banking app designed to revolutionize how individuals manage their finances. By leveraging blockchain technology, Vista Finance offers unparalleled financial freedom, security, and convenience in the world of decentralized finance (DeFi)."
            ghLink="https://www.youtube.com/watch?v=PSqE3yq6tOk"
            demoLink=""
          />
          <ProjectCard
            imgPath={v2}
            isBlog={true}
            title=" Vista Force New Mlm plan launch today"
            description="Vista Finance is committed to driving innovation across multiple sectors, providing cutting-edge solutions in decentralized finance, digital art, and trading education. With its diverse range of services, including ArtCart and Block Vista Trading Academy, Vista Finance fosters growth and empowers individuals globally in the digital economy."
            ghLink="https://www.youtube.com/watch?v=PSqE3yq6tOk"
            demoLink=""
          />
          <ProjectCard
            imgPath={plan}
            isBlog={false}
            title="Vista Force"
            description="Vista Finance is launching an innovative decentralized banking app that aims to transform how people manage their finances. Utilizing blockchain technology, Vista Finance ensures unmatched security, freedom, and convenience in the rapidly growing decentralized finance (DeFi) sector."
            ghLink=""
            demoLink="Hello"
          />

      </Row>
      <Row>
          <Col md={12} className="home-about-social">
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.reddit.com/user/Then_Fondant_8184/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaReddit />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://x.com/vistafound12"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/company/vistafinance13/posts/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaLinkedin />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/fbvistafoundation/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://medium.com/@vistafoundation"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <FaMedium />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/thevistafoundation/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
    </Container>
  </Container>
  );
}
export default Home2;
