import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { AiOutlineDownload } from "react-icons/ai";
import { Col } from "react-bootstrap";
import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

function ProjectCards(props) {
  const [width, setWidth] = useState(1200);
  useEffect(() => {
    setWidth(window.innerWidth);
  }, []);

  return (
    <Col md={4} className="project-card">
    
        {props.ghLink && (
          <Card className="project-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{ textAlign: "justify" }}>
          {props.description}
        </Card.Text>
        <Button variant="primary" href={props.ghLink} target="_blank">
          {props.isBlog ? "Watch Video" : "Visit"}
        </Button>
        {"\n"}
        {"\n"}

      </Card.Body>
      </Card>
        )}

        {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}

        {props.demoLink && (
          <Card className="project-card-view">
            
          <Document file={props.imgPath} className="d-flex justify-content-center">
            <Page pageNumber={1} scale={width > 786 ? 0.40 : 0.34} />
          </Document>
          <Card.Body>
            <Card.Title>{props.title}</Card.Title>
            <Card.Text style={{ textAlign: "justify" }}>
              {props.description}
            </Card.Text>
          <Button
          variant="primary"
          href={props.imgPath}
          target="_blank"
          style={{ maxWidth: "250px", marginTop: "10px" }}
        >
          <AiOutlineDownload />
          &nbsp;Download
        </Button>
        {"\n"}
        {"\n"}

      </Card.Body>
      </Card>
        )}
        </Col>
  );
}
export default ProjectCards;
